import * as React from "react"
import Layout from "../components/layout"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false
 
const contact = () => {
    return (
        <Layout>
            <section className="w-full max-w-2xl px-6 pb-8 pt-10 mx-auto lg:pb-20 lg:pt-52">
                <h2 className="timing-ease-in-out change-time15 box fadeUp text-5xl text-center text-gray-600 en mb-10 lg:mb-16">Get in touch</h2>
                <p className="timing-ease-in-out change-time1 delay-time05 box fadeUp mt-3 text-gray-400 text-ms text-left mb-8 lg:mb-16">特定の状況を除き早急な返答をこころがけていますが、万が一24時間以上返信が無い場合はSNSのDMからご連絡いただけますと確実かと思われます。
                </p>
                <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" className="mt-6 ">
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="hidden" name="bot-field" />
                    <div className="items-center -mx-2 md:flex">
                        <div className="w-full mx-2">
                            <label className="block mb-2 text-sm font-medium text-gray-600 ">Name</label>
                            <input type="text" name="name" className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md    focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                         </div>
                    <div className="w-full mx-2 mt-4 md:mt-0">
                        <label className="block mb-2 text-sm font-medium text-gray-600 ">E-mail</label>
                         <input type="email" name="email" className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md  focus:border-blue-400 focus:ring-blue-300 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" /> 
                    </div>
                    </div>

            <div className="w-full mt-4">
                <label className="block mb-2 text-sm font-medium text-gray-600 ">Message</label>

                <textarea name="message" className="block w-full h-40 px-4 py-2 text-gray-700 bg-white border rounded-md  focus:border-blue-400  focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"></textarea>
            </div>

            <div className="flex justify-center mt-6">
                <button className="px-4 py-2 text-white transition-colors duration-200 transform bg-blue-400 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Send Message</button>
            </div>
        </form>
    </section>

</Layout>
    )
}

export default contact